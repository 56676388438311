$bg: #fff;
$footer: #f1f1f1;

$codeFg: #DC143C;
$codeBg: #EEEEEE;
$codeBr: rgba(0,0,0,0.15);
$highlightBg: #fff3ce;

$warning: #fdd;
$update: #d1ecf1;
$note: #fff3ce;
$tip: #d4edda;

$black: #222;
$white: #fff;
$blue: #0037FF;
$green: #7E9F5B;
$orange: #ffa600;
$red: #FF6500;
$magenta: #982393;
$grey: #c2bebe;
$darkGrey: #555;
$silver: #ddd;

$darkBg: #111;
$lightBlue: #fda53c;

// Mobile breakpoints
@mixin mobile {
  @media screen and (max-width: 640px) {
    @content;
  }
}
