@use "variables" as *;
@use "default" as *;

/* the selector here could be :root, html or body */
:root {
  color-scheme: light dark;
}

html {
    font-family: Sans-serif;
    background: $white;
    line-height: 1.4;
    margin: auto;
    color: #111;
}

body {
    margin: 0;
    padding: 0;
    max-width: 760px; margin: 0 auto; padding: 0 1em;
}

.left { float: left; }
.right { float: right; }

h1,h2,h3,h4,h5,h6 { margin: 1em 0 .5em; }

h1 { font-size: 2em;}
h2 { font-size: 1.5em;}
h3 { font-size: 1.17em;}
h4 { font-size: 1em;}
h5 { font-size: .83em;}
h6 { font-size: .67em;}

a { color: $blue; }
a:visited { color: $magenta; }
a:hover { color: $magenta; }

hr { border: 0; height: 0; border-bottom: 1px solid rgba(0,0,0,.1); }

/*---------------------------------------------------------------------------*/
/* Header
/*---------------------------------------------------------------------------*/

header {
    margin-bottom: 2em;

    h1 a {
        text-decoration: none;
    }
    h1 a, h1 a:visited {
        color: $black;
    }
}

/*---------------------------------------------------------------------------*/
/* Page Content
/*---------------------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/
/* Page: About
/*---------------------------------------------------------------------------*/

.avatar {
    margin-left: 0.5em;
    margin-bottom: 0.75em;
    border: none;
    @include mobile {
        max-width: 100px;
    }
}

/*---------------------------------------------------------------------------*/
/* Post: Content
/*---------------------------------------------------------------------------*/

article {
  .date { margin-bottom: 2em; }

    kbd {
      border: 0.2em solid;
      border-color: #DDD #BBB #BBB #DDD;
      border-radius: 3px;
      padding: 0 0.4em;
      background: #EEE;
      font-size: 14px;
    }

    ///* Code highlight */

    .highlight, code {
        background: $codeBg;
        padding: 2px 7px;
        font-size: 14px;
        code { background: $codeBg; }
    }

    code { background: $codeBg; }

    .highlight { display: flex; }

    .highlighter-rouge {
        border: 1px solid rgba(0,0,0,0.1);
        border-radius: 2px;
        white-space: pre;
        margin-bottom: .25em;
    }

    pre, pre code { overflow:auto }

    ///* Images & Videos */

    img {
        display: block;
        max-width: 100%;
        border-radius: 3px;
        margin: 0 auto;
        border: 1px solid rgba(0,0,0,0.15);
    }

    figure {
        img {
            display: inline-table;
            max-width: 100%;
            border-radius: 3px;
            margin: 0 0.5em;

            @include mobile {
                margin: unset;
            }
        }
    figcaption {
            font-size: 90%;
        }
    }

    .grid-container {
        display: flex;
        @include mobile { display: unset; }
    }

    .grid-item {
        margin: 0 auto;
        text-align: center;
        font-size: 14px;
        padding: 0 1px;

        @include mobile {
            max-width: 100%;
        }
    }

    figure {
        margin: 2em auto;
        padding: 0;
        text-align: center;
        a, a:hover { text-decoration: none; }
    }

    figcaption {
        text-align: center;
        color: $black;

        @include mobile {
            margin: .5em 0;
        }
    }

    /* Quotes */

    blockquote {
        background: rgba(0,0,0,0.05);
        padding: .25em 1.5em;
        margin-left: 1em;
        border-left: 5px solid rgba(0,0,0,0.15);
        font-style: italic;
    }

    /* Tables */

    table {
        border-collapse: collapse;
        width:100%;
        margin: 1.5em 0;
    }

    td, th {
        border: 1px solid $grey;
        padding: .5em;
        code {
            white-space: pre;
        }
    }

    th {
        text-align: left;
        background: rgba(0,0,0,.1);
    }

    tr:nth-child(even){
        background: rgba(0,0,0,.03);
    }

    /* Page Last Updated */
    .update { text-align: center;
        padding: 1.5em 0 0.5em 0;
        margin-bottom: 1em;
    }

    .note,
    .old,
    .warning {
        border-radius: 3px;
        padding: 0.75em 1em;
        border: 1px solid rgba(0,0,0,0.1);
        margin-bottom: 1.5em;
        display: block;
    }

    .note::before,
    .old::before,
    .warning::before {
        color: rgba(0,0,0,0.75);
        display: block;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        padding-bottom: 0.5em;
        margin-bottom: 0.5em;
        font-weight: bold;
    }

    .note { background: $note; }
    .old { background: $note; }
    .warning { background: $warning; }

    .note::before { content: "🗒️ Notis"; }
    .old::before { content: "🗒️ Det här är ett gammalt inlägg!"; }
    .warning::before { content: "Varning!"; }

}

/*---------------------------------------------------------------------------*/
/* Post: Meta
/*---------------------------------------------------------------------------*/

.meta { border-top: 1px solid $silver;
    margin-top: 2em;
}

/*---------------------------------------------------------------------------*/
/* Footer
/*---------------------------------------------------------------------------*/

footer {
    max-width: 760px;
    margin: 0 auto;
    text-align: center;
    border-top: 1px solid rgba(0,0,0,.1);
    padding: 1em 0;
    margin-top: 2em;
}

/*---------------------------------------------------------------------------*/
/* Dark Mode
/*---------------------------------------------------------------------------*/

@media (prefers-color-scheme: dark) {
    html {
        color: #FFFFFF;
        background-color: #000000;
    }
    header h1 a, header h1 a:visited { color: $white; }
    a { color: $orange; }
    a:visited { color: $red; }
    a:hover { color: $red; }
    article {
        code, .highlight, .highlight code {
            background: #434540;
        }
        .highlighter-rouge {
            border: none;
        }
        figcaption {
            color: #FFFFFF;
        }
        .note, .note::before {
            background: #434540;
            color: #FFFFFF;
        }
    }
}
